<template>
  <section v-show="markerInfo" id="airportSlot">
    <b-card>
      <div class="d-flex align-items-center">
        <h1 class="my-2 mr-1">{{ $t('timeline.validate_airport.airport_of') }} {{ eventData.city.label }}</h1>
        <b-badge variant="light-danger">
          {{ $t('timeline.validate_airport.congested_airport') }}
        </b-badge>
      </div>
      <b-row class="match-height">
        <!--INFO-->
        <b-col cols="4">
          <b-card class="border-primary">
            <h4>
              <div class="font-weight-bold mb-1">{{ $t('trip.event.requested_slot') }} {{ $t('common.for') }} {{ eventData.categorie }}:</div>
              <span class="font-weight-normal font-medium-1">
                <span class="mb-50">{{ eventData.slot.start | moment('ddd, MMM Do YYYY [:] HH:mm') }}</span> -
                <span>{{ eventData.slot.end | moment('HH:mm') }}</span>
              </span>
            </h4>
            <div>
              <validation-observer #default="{ handleSubmit }" ref="addSlotRules">
                <b-form class="pt-1" @submit.prevent="handleSubmit(onSubmit)">
                  <validation-provider #default="{ errors }" name="slot number" rules="required" class="validation-required">
                    <b-form-group :label="$t('trip.validate_airport.submit_slot.slot_number')" label-for="slotNumber">
                      <b-form-input v-model="slotNumber" :state="errors.length > 0 ? false : null" placeholder="Slot number" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider #default="{ errors }" :name="$t('common.date') | capitalize" rules="required" class="validation-required">
                    <b-form-group :label="$t('common.date') | capitalize" label-for="slot-hour">
                      <b-form-datepicker
                        v-model="slotDateSelected"
                        :state="errors.length > 0 ? false : null"
                        class="mb-1"
                        placeholder="Date"
                        :locale="$i18n.locale"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider #default="{ errors }" :name="$t('common.time_zone') | capitalize" rules="required" class="validation-required">
                    <b-form-group class="mt-1" :label="$t('common.time_zone')" label-for="timeZones" :state="errors.length > 0 ? false : null">
                      <v-select
                        v-model="slotUTC"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="UTCOptions"
                        :reduce="val => val.label"
                        :clearable="false"
                        :searchable="false"
                        input-id="UTC-select"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider #default="{ errors }" :name="$t('common.hour') | capitalize" rules="required" class="validation-required">
                    <b-form-group :label="$t('common.hour') | capitalize" label-for="slot-hour">
                      <b-input-group class="mb-1">
                        <b-form-input id="slot-hour" v-model="slotHour" type="text" placeholder="HH:mm" :state="errors.length > 0 ? false : null" />
                        <b-input-group-append>
                          <b-form-timepicker v-model="slotHour" button-only button-variant="outline-primary" right size="sm" aria-controls="slot-hour" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <!-- FOOTER -->
                  <div class="d-flex justify-content-end mt-2">
                    <b-button class="mr-2" type="submit">
                      <font-awesome-icon icon="calendar-check" class="mr-50" />
                      {{ $t('trip.event.slot.submit') }}
                    </b-button>
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary">
                      <font-awesome-icon icon="ban" class="mr-50" />
                      {{ $t('action.cancel') }}
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </b-card>
        </b-col>
        <!--CALENDAR-->
        <b-col cols="8">
          <b-card class="border-primary">
            <b-card-title>
              {{ $t('timeline.validate_airport.slot_selection.title') }}
            </b-card-title>

            <div class="app-calendar overflow-hidden border">
              <div class="row no-gutters">
                <!-- Calendar -->
                <div class="col position-relative">
                  <div class="card shadow-none border-0 mb-0 rounded-0">
                    <div class="card-body pb-0">
                      <b-form-datepicker
                        v-model="dateSelected"
                        :locale="$i18n.locale"
                        :no-flip="true"
                        :placeholder="$t('timeline.validate_airport.select_date')"
                        class="mb-1"
                      />
                      <full-calendar ref="calendarSlot" :options="calendarOptions" class="full-calendar" :plugins="calendarOptions.plugins" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

import { patchTripStepValidateAirportRequest } from '@/request/globalApi/requests/tripStepRequests'

export default {
  name: 'AirportSubmitSlot',

  components: {
    FullCalendar,
  },

  props: {
    markerInfo: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      dateSlotSelected: '',
      dateCalendarSelected: '',
      timeSlotSelected: '',
      currentDate: null,
      calendarOptions: {
        timeZone: 'UTC',
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'timeGridWeek',
        allDaySlot: false,
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'timeGridWeek,listMonth',
        },
        events: [
          {
            id: 20,
            title: '20',
            startTime: '07:00:00+00:00',
            endTime: '08:00:00+00:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            color: '#ea5455',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: { slots: { percentage: 0 } },
          },
          {
            id: 1,
            title: '20',
            startTime: '08:00:00+00:00',
            endTime: '09:00:00+00:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            color: '#28c76f',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: { slots: { percentage: 7 } },
          },
          {
            id: 2,
            title: '5',
            startTime: '09:00:00+00:00',
            endTime: '10:00:00+00:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 5, 6],
            color: '#ED8F03',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: { slots: { percentage: 5 } },
          },
          {
            id: 3,
            title: '5',
            startTime: '10:00:00+00:00',
            endTime: '11:00:00+00:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 5, 6],
            color: '#ED8F03',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: { slots: { percentage: 5 } },
          },
          {
            id: 4,
            title: '5',
            startTime: '11:00:00+00:00',
            endTime: '12:00:00+00:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 5, 6],
            color: '#ED8F03',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: { slots: { percentage: 5 } },
          },
          {
            id: 5,
            title: '7',
            startTime: '12:00:00+00:00',
            endTime: '13:00:00+00:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            color: '#28c76f',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: { slots: { percentage: 75 } },
          },
          {
            id: 6,
            title: '7',
            startTime: '13:00:00+00:00',
            endTime: '14:00:00+00:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            color: '#28c76f',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: { slots: { percentage: 75 } },
          },
          {
            id: 7,
            title: '7',
            startTime: '14:00:00+00:00',
            endTime: '15:00:00+00:00',
            allDay: false,
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            color: '#28c76f',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: { slots: { percentage: 75 } },
          },
          {
            id: 8,
            groupId: 'slot2',
            title: '7',
            startTime: '09:00:00+00:00',
            endTime: '10:00:00+00:00',
            allDay: false,
            daysOfWeek: [4],
            color: '#28c76f',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: { slots: { percentage: 7 } },
          },
          {
            id: 9,
            title: '7',
            startTime: '10:00:00+00:00',
            endTime: '11:00:00+00:00',
            allDay: false,
            daysOfWeek: [4],
            color: '#28c76f',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: { slots: { percentage: 7 } },
          },
          {
            id: 10,
            title: '7',
            startTime: '11:00:00+00:00',
            endTime: '12:00:00+00:00',
            allDay: false,
            daysOfWeek: [4],
            color: '#28c76f',
            textColor: 'white',
            startRecur: '2021-07-04',
            endRecur: null,
            extendedProps: { slots: { percentage: 7 } },
          },
        ],
        selectable: true,
        eventClick: currentSlot => {
          if (currentSlot.event.extendedProps.slots.percentage) {
            this.confirmSlot(currentSlot)
          }
        },
        eventResizableFromStart: true,
        dragScroll: true,
        navLinks: true,
        locale: this.$i18n.locale,
      },
    }
  },
  computed: {
    flightData() {
      const tripId = this.$route.params.trip_id
      return this.$store.getters['trip/currentTrip'](tripId)
    },
    eventData() {
      const eventId = this.$route.params.event_id
      const currentTrip = this.flightData
      const currentStep = currentTrip.tripSteps.find(step => step.id === eventId)
      return currentStep
    },
  },
  watch: {
    dateCalendarSelected(val) {
      const calendarApi = this.$refs.calendarSlot.getApi()
      calendarApi.gotoDate(val)
    },
    markerInfo() {
      this.$nextTick(() => {
        this.dateCalendarSelected = this.tripLeg.eventDateTime
        this.dateSlotSelected = this.tripLeg.eventDateTime
        this.timeSlotSelected = this.$moment(this.tripLeg.eventDateTime).format('HH:mm')
      })
    },
  },
  mounted() {
    const calendarApi = this.$refs.calendarSlot.getApi()
    calendarApi.gotoDate(this.tripLeg.eventDateTime)
  },
  methods: {
    /* CALL API */
    postSlot(slot) {
      const eventId = this.$route.params.event_id
      const tripId = this.$route.params.trip_id
      patchTripStepValidateAirportRequest(eventId, this.tripLeg.id, slot).then(() => {
        this.$router.push({
          name: 'trip-view',
          params: { trip_id: tripId },
        })
      })
    },
    confirmSlot(slotFromCalendar) {
      let slot = null
      let day = ''
      let startTime = ''
      let endTime = ''
      if (slotFromCalendar) {
        slot = {
          startsAt: slotFromCalendar.event.start,
          endsAt: slotFromCalendar.event.end,
          date: slotFromCalendar.event.start,
          airportId: this.markerInfo.id,
        }
        day = this.$options.filters.dateFormat(slot.endsAt.getDay())
        startTime = this.$moment(slot.startsAt).utc().format('HH:mm')
        endTime = this.$moment(slot.endsAt).utc().format('HH:mm')
      } else {
        const startConcat = this.$moment(`${this.dateSlotSelected} ${this.timeSlotSelected}`, 'YYYY-MM-DD HH:mm:ss').format()

        slot = {
          startsAt: startConcat,
          endsAt: this.$moment(startConcat).add(1, 'hours'),
          date: this.dateSlotSelected,
          airportId: this.markerInfo.id,
        }
        day = this.$options.filters.dateFormat(slot.date)
        startTime = this.$moment(slot.startsAt).format('HH:mm')
        endTime = this.$moment(slot.endsAt).format('HH:mm')
      }

      this.$swal({
        title: this.$t('alert.delete_confirmation.title'),
        text: `${this.$t('alert.timeline.validate_airport.slot_selected.message')}
        ${this.$t('timeline.validate_airport.slot_selection.on_date')} ${day}
        ${this.$t('timeline.validate_airport.slot_selection.from_hour')} ${startTime}
        ${this.$t('timeline.validate_airport.slot_selection.to_hour')} ${endTime} `,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('common.yes'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-dark ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.postSlot(slot)
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';

.fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100% !important;
}

.fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-col-header {
  width: 100% !important;
}

.fc-selectDateBtn-button {
  margin-left: 2rem !important;
  border-color: #dcc181 !important;
  background-color: #dcc181 !important;
}
</style>
